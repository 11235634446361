<template>
  <v-container>
    <!-- Header -->
    <header class="custom-header mb-6">
      <h4>නව සාමාජිකයින්</h4>
    </header>

    <v-data-table
      :footer-props="$store.state.dataTableFooterProps"
      :headers="headers"
      :items="members"
      :loading="loading"
      :mobile-breakpoint="960"
      :options.sync="options"
      :server-items-length="total"
      @click:row="(member) => $router.push(`/aw-admin/new-members/${member.id}`)"
      class="custom-shadow custom-radius"
    >
      <template slot="no-data">ලබා ගත හැකි දත්ත නැත</template>
      <template v-slot:item.nicVerify="{ item }">
        {{item.nic}}
        <v-btn
          @click.stop="verify(item, 'nicVerified')"
          color="accent"
          icon
          v-if="!item.nicVerified"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.mobileNumberVerify="{ item }">
        {{item.mobileNumber}}
        <v-btn
          @click.stop="verify(item, 'mobileNumberVerified')"
          color="accent"
          icon
          v-if="!item.mobileNumberVerified"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn @click.stop="openDialog(item)" color="error" depressed small>අත්හිටුවන්න</v-btn>
      </template>
    </v-data-table>

    <!-- Dialog -->
    <v-dialog max-width="600" persistent v-model="dialog">
      <v-card>
        <v-form @submit.prevent="suspend" lazy-validation ref="form">
          <v-card-text class="pb-0">
            <v-select
              :items="reasons"
              :rules="[v => v.length > 0 || 'අවශ්‍යයි']"
              @input="enableSuspensionReasonTextArea"
              chips
              dense
              label="අත්හිටුවීමට හේතුව"
              multiple
              v-model="suspensionReasonTemp"
            ></v-select>
            <v-textarea
              :rules="[requiredRule, lengthRule]"
              auto-grow
              counter
              label="අත්හිටුවීමට හේතුව"
              rows="1"
              v-if="isEnabledTextArea"
              v-model="suspensionReason"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false" color="error" text>අවලංගු කරන්න</v-btn>
            <v-btn color="success" text type="submit">තහවුරු කරන්න</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import { CHANGE_LOADING_STATE, SHOW_ALERT } from "../../store/mutation-types";
import { ApiService } from "../../services/api.service";
import ConfirmDialog from "../../components/ConfirmDialog";

export default {
  components: {
    ConfirmDialog
  },

  data: () => ({
    requiredRule: v => !!v || 'අවශ්‍යයි',
    lengthRule: v => v.length <= 255 || 'උපරිම අක්ෂර 255 ක් සඳහා පමණක් අවසර ඇත',
    headers: [
      { text: "සාමාජික අංකය", value: "id", sortable: false },
      { text: "ලියාපදිංචි වූ දිනය", value: "created_at" },
      { text: "ජාතික හැඳුනුම්පත", value: "nicVerify", sortable: false },
      { text: "දුරකතන අංකය", value: "mobileNumberVerify", sortable: false },
      { text: "ක්‍රියා", value: "action", align: "right", sortable: false }
    ],
    members: [],
    loading: false,
    options: {},
    total: 0,
    dialog: false,
    selectedMember: null,
    reasons: [
      'තොරතුරු නිසි ලෙස තහවුරු නොකිරීම',
      'කටයුත්තක් තීන්දුවූ බව දැන්වීම',
      'කටයුත්තක් තීන්දුවී ඇති බවට වෙනත් සාමාජිකයකු විසින් දැනුම් දීම',
      'ඇමතුම් වලට නිසි ප්‍රතිචාර නොදැක්වීම',
      'දුරකථන අංක අවභාවිතය හා අනිසි බලපෑම් ගැන චෝදනා එල්ලවීම',
      'වෙනත්'
    ],
    suspensionReason: '',
    suspensionReasonTemp: [],
    isEnabledTextArea: false
  }),

  async mounted() {
    this.$store.commit(CHANGE_LOADING_STATE, { active: true });
    await this.fetchNewMembers();
    this.$store.commit(CHANGE_LOADING_STATE, { active: false });
  },

  watch: {
    options: {
      handler: function () {
        this.fetchNewMembers();
      },
      deep: true,
    }
  },

  methods: {
    ...mapMutations({ showAlert: SHOW_ALERT }),

    fetchNewMembers: async function () {
      this.loading = true;

      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const order = sortDesc[0] ? 'desc' : 'asc';
      const pageOptions = itemsPerPage != -1 ? { perPage: itemsPerPage, page: page } : {};
      const sortOptions = sortBy.length != 0 ? { sortBy: sortBy[0], order: order } : {};

      try {
        const url = "customers/new";
        const params = { params: { ...pageOptions, ...sortOptions } };
        const response = await ApiService.get(url, params);
        this.members = response.data.data;
        this.total = response.data.meta.total;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    // Verify nic or mobile number
    verify: async function (member, property) {
      if (await this.$refs.confirm.open('සත්‍යාපනය කරන්න', 'ඔබට විශ්වාසද ?', { color: 'success' })) {
        this.loading = true;
        try {
          const url = "customers/verify";
          const data = { customer: member.id, property: property, value: true };
          const response = await ApiService.put(url, data);
          member[property] = response.data[property];

          // If both properties are verified, remove from new members list
          if (response.data.nicVerified && response.data.mobileNumberVerified) {
            const index = this.members.indexOf(member);
            this.members.splice(index, 1);
          }

          let message = "ජාතික හැඳුනුම්පත් අංකය සත්‍යාපනය කරන ලදි";

          if (property == "mobileNumberVerified") {
            message = "දුරකථන අංකය සත්‍යාපනය කරන ලදි";
          }

          this.showAlert({ message: message, type: "success" });
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    // Suspend a member
    suspend: async function () {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.loading = true;
        try {
          const url = 'customers/approve';
          const data = {
            approved: false,
            suspensionReason: this.isEnabledTextArea ? this.suspensionReason : this.suspensionReasonTemp.join(),
            customer: this.selectedMember.id
          };
          const response = await ApiService.put(url, data);
          const index = this.members.indexOf(this.selectedMember);
          this.members.splice(index, 1);
          this.showAlert({ message: "ගිණුම අත්හිටුවන ලදි", type: "success" });
          this.$refs.form.resetValidation();
          this.suspensionReason = "";
          this.suspensionReasonTemp = [];
          this.isEnabledTextArea = false;
        } catch (error) {
          console.log(error);
        }
        this.loading = false;
      }
    },

    openDialog: function (member) {
      this.dialog = true;
      this.selectedMember = member;
    },

    // Enable suspension reason text area
    enableSuspensionReasonTextArea: function (arr) {
      if (arr.includes("වෙනත්")) {
        this.suspensionReasonTemp = ['වෙනත්'];
        this.isEnabledTextArea = true;
      } else {
        this.isEnabledTextArea = false;
      }
    }
  }
}
</script>